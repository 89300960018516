import React, { useRef } from "react";
import { useCss, a, k } from "kremling";
import { CpContextDropdown, CpButton, CpIcon, CpDropdown } from "canopy-styleguide!sofe";
import { SignerPill } from "../esign/page-layout-components/signer-pill.component";
import { typeIconMatch, signingFieldTypes } from "../constants";

// TODO move into own component
const FieldTypeButton = ({ type, currentType, icon, label }) => (
  <button className={`cp-select-list__item ${currentType === type ? "cp-select-list__item--selected" : ""}`}>
    <CpIcon className="cp-select-list__icon-left" name={icon} />
    <span>{label}</span>
    {currentType === type && (
      <CpIcon name="checkmark-small" style={{ marginLeft: "auto" }} fill="var(--cp-color-app-success-text)" />
    )}
  </button>
);

const fieldTypes = [
  { type: signingFieldTypes.TEXT, label: "Text field", icon: typeIconMatch[signingFieldTypes.TEXT] },
  { type: signingFieldTypes.SIGNATURE, label: "Signature", icon: typeIconMatch[signingFieldTypes.SIGNATURE] },
  { type: signingFieldTypes.INITIAL, label: "Initials", icon: typeIconMatch[signingFieldTypes.INITIAL] },
  { type: signingFieldTypes.DATE, label: "Date", icon: typeIconMatch[signingFieldTypes.DATE] },
];

export default function SignerFieldToolbar({
  // forCurrentUser,
  signingObject,
  // openSignatureEntryModal,
  deleteSigningField,
  signerPillProps,
}) {
  const dropdownRef = useRef();
  const scope = useCss(css);
  const fieldIcon = typeIconMatch[signerPillProps.type];

  const handlePillClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dropdownRef.current.open({
      top: -4,
      left: 0,
    });
  };

  return (
    <div {...scope}>
      <div onClick={handlePillClick}>
        <SignerPill {...signerPillProps} />
      </div>

      <CpContextDropdown
        ref={dropdownRef}
        contentWidth="auto"
        position="top-start"
        allowContentClicks={true}
        renderContent={({ close }) => (
          <div className="toolbar-styling">
            <div className="signer-name">
              <CpIcon
                name="shape-circle-closed"
                className="cp-mr-4"
                fill={
                  signerPillProps.teamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-default-badge-bg)"
                }
              />
              {signerPillProps.text}
            </div>
            <div className="toolbar-divider" />
            {/* TODO hook up change field button  */}
            <CpDropdown
              renderTrigger={({ toggle }) => (
                <CpButton
                  icon={fieldIcon}
                  aria-label="Change Field"
                  btnType="icon-dark"
                  className="cp-mh-4"
                  onClick={(e) => toggle(e)}
                >
                  Options
                </CpButton>
              )}
              renderContent={() => (
                <div className="cp-select-list">
                  {fieldTypes.map(({ type, label, icon }) => (
                    <FieldTypeButton
                      key={type}
                      type={type}
                      currentType={signerPillProps.type}
                      icon={icon}
                      label={label}
                    />
                  ))}
                </div>
              )}
            />
            {/* TODO hook up duplicate button  */}
            <CpButton icon="crud-duplicate" aria-label="Duplicate" btnType="icon-dark" className="cp-mh-4" />
            {/* TODO hook up settings button  */}
            {signerPillProps.type === signingFieldTypes.SIGNATURE && (
              <CpButton icon="misc-gear" aria-label="Settings" btnType="icon-dark" className="cp-mh-4" />
            )}
            <CpButton
              icon="crud-trash-large"
              aria-label="Delete"
              onClick={() => deleteSigningField(signingObject.id)}
              btnType="icon-dark"
              className="cp-mh-4"
            />
          </div>
        )}
      />
    </div>
  );
}

const css = `
  .toolbar-styling {
    min-width: 150px;
    width: auto;
    height: 40px;
    white-space: nowrap;
    background: var(--cp-color-toast-bg);
    border-radius: 5px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .signer-name {
    display: flex;
    align-items: center;
    color: var(--cp-color-primary-nav-button-icon);
    margin-right: 8px;
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
    font-weight: 600;
  }
  .toolbar-divider {
    width: 1px;
    height: 20px;
    background-color: var(--cp-color-nav-active-border);
    margin: 0 8px;
  }
`;
